/* global variables */

.ql-editor{
  min-height:200px;
}

/* Set content for font-families 
.ql-font-arial,
.ql-font span[data-value="arial"]::before {
  font-family: Arial, sans-serif;
}
.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
  font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
  font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
  font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}*/

/* Set content for sizes */
.ql-size-8px,
.ql-size span[data-value="8px"]::after {
  font-size: 8px !important;
}
.ql-size-10px,
.ql-size span[data-value="10px"]::after {
  font-size: 10px !important;
}
.ql-size-12px,
.ql-size span[data-value="12px"]::after {
  font-size: 12px !important;
}
.ql-size-14px,
.ql-size span[data-value="14px"]::after {
  font-size: 14px !important;
}
.ql-size-16px,
 span[data-value="16px"]::after {
  font-size: 16px !important;
}

.ql-size.customSize {
  width: 50px !important;
}

/* Custom */
.customBarElem {
color: #444;
display: inline-block;
float: left;
font-size: 14px;
font-weight: 500;
height: 24px;
position: relative;
vertical-align: middle;
margin-right: 10px;
}

.quill > .ql-container > .ql-editor.ql-blank::before{
  color: var(--quill-placeholder);
}

.selectVoice {
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px 5px;
  width: 140px;
  font-size: 14px;
  color: black;
}

.voiceChoice {
  display: inline-block !important;
  background: none;
  border: none;
  cursor: pointer;
  width: 80px;
  font-size: 14px;
  font-weight: bold;
}

.selectSilence{
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px 5px;
  width: 140px;
  font-size: 14px;
}

.left-arrow {
  width: 22px;
  height: 22px;
}

.paste {
  width: 22px;
  height: 22px;
}

.location-m {
  width: 22px;
  height: 22px;
}

.para {
  width: 20px;
  height: 20px;
}

.voiceSelector .ql-picker, .silenceTypeSelector .ql-picker{
  display: none !important;
}

.voiceSelector{
  display: inline-block;
  border-left: 1px solid #ccc;
  padding-left: 10px;
}

.silenceTypeSelector{
  display: inline-block;
  padding-right: 10px;
}

.undo{
  border-right: 1px solid #ccc !important;
  width: 50px !important;
}

.start-reading{
  width: 28px !important;
}

.font-size{
  margin-right: 10px;
}

/* Dictionary */

.add-line-btn{
  float: right;
  background: var(--orange) !important;
}

.add-line-btn svg {
  margin-top: -2px;
}

.add-line-btn:hover{
  border: none;
  background-color: var(--orange-hover) !important;
}

.icon-btn{
  background: none;
  border: none;
  color: var(--grey);
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.icon-btn:hover{
  color: var(--blue);
  background: none;
  border: none;
}

.delete-btn{
  margin-left: 10px;
}

.rdt_TableHeader{
  display: none !important;
}

.rdt_TableHeadRow {
  background-color: var(--background-primary) !important;
  border-bottom-color: var(--border-dict) !important;
  min-height: 40px !important;
}

.rdt_TableRow {
  background-color: var(--background-primary) !important;
  border-bottom-color: var(--border-dict) !important;
  min-height: 40px !important;
}

.rdt_TableCell {
  background-color: var(--background-primary) !important;
  color: var(--text-color);
}

.rdt_TableCol{
  background-color: var(--background-primary) !important;
  white-space: pre;
}

.rdt_TableCol_Sortable {
  color: var(--text-color) !important;;
}

.rdt_Pagination {
  color: var(--pagination-dict) !important;
  background-color: var(--background-primary) !important;
  border-top-color: var(--border-dict) !important;
}

.dQdisw {
  fill: var(--pagination-dict) !important;
}

.close-dict-btn{
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-block;
  color: var(--grey);
}

.close-dict-btn:hover{
  color: var(--blue);
}

.title{
  display: inline-block;
}

.action-btn{
  height: 42px;
  width: 180px ;
  background-color: var(--blue);
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border: 0px;
  border-radius: 0px;
}

.action-btn:hover, .action-btn:focus,.action-btn:active{
  background-color: var(--blue-hover) !important;
}

.postion-action-btn{
  float: right;
}

.text-to-insert{
  cursor: cell;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label-icon{
  width: 30px;
  height: 30px;
  margin-right: 20px;
  fill: var(--text-color);
}

.modal-header .close {
  color: var(--text-color);
}

.form-control {
  background-color: var(--background-primary) !important;
  color: var(--text-color) !important;
}

.search-icon{
  color: var(--blue);
}

.search-block{
  position: absolute;
  left: 0;
  border-bottom: 1px solid var(--blue);
}

.search-block input{
  color: var(--text-color);
  background-color: var(--background-primary);
  border: 0px;
  padding-bottom: 5px;
}

.search-block input::placeholder{
  font-size: 14px;
}

.search-block input:focus{
  outline: none;
}

header{
  background-color: var(--background-primary) !important;
  padding-top: 0px !important;
}

/* Quill */

.ql-container{
  min-height: 250px;
}

.ql-toolbar{
  background-color: var(--grey);
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  width: 33px !important;
}

/*.ql-picker-label[data-value="Denise"]::before {
  content: "Denise";
}

.ql-picker-label[data-value="Henry"]::before {
  content: "Henry";
}

.ql-picker-item[data-value="Henry"]::before {
  content: "Henry";
}

.ql-picker-item[data-value="Denise"]::before {
  content: "Denise";
}*/