/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

/*.headerRow {
  margin-top: 20px;
}*/

/* global variables */

.mt-1 {
  margin-top: 1rem !important;
}

.header{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-bottom: 8px solid var(--blue);
}

.logoHeader {
  text-align: left;
  height: auto;
  width: 80%;
}

.lightbulb-btn {
  width: 35px;
  height: 35px;
  display: block;
  margin: 0 auto;
  height: auto;
}

.user-btn {
  width: 32px;
  height: 35px;
  display: block;
  margin: 0 auto;
  height: auto;
  margin-top: 6px;
}

.logout-btn {
  width: 25px;
  height: 25px;
  display: block;
  margin: 0 auto;
  height: auto;
  margin-top: 6px;
}

.titleHeader {
  text-align: left;
  height: auto;
  font-size: 36px;
  color: var(--blue);
  font-weight: bold;
}

.container-fluid{
  margin-bottom: 5%;
}

.btn-circle {
  width: 35px;
  height: 35px;
  line-height: 35px !important;
  font-size: 0.9rem !important;
  border: none !important;
  text-align: center !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.btn-circle svg, .btn-circle-lg svg{
  height: 30px;
  width: 30px;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
  border: none !important;
  text-align: center !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.generate-btn{
  margin-top: 2%;
}

.content-row{
  margin-top: 30px;
  padding-left: 5%;
  padding-right: 5%;
}

.blue-bar {
  height: 3px;
  width: 37px;
  border-radius: 2.5px;
  background-color: var(--blue);
  margin-top: 3px;
  margin-bottom: 20px;
}

.title-block{
  font-weight: bold;
  color: var(--blue);
}

.download-btn{
  bottom: 20px;
  margin-left: 15px;
}

.modal-body .form-label{
  text-transform: uppercase;
}


.tooltip > div.tooltip-inner{
  background-color: #d3d3d3 !important;
  color: black;
}
.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  border-top-color:  #d3d3d3 !important;
}

/* Custom for App page */
.open-dict-btn{
  background-color: var(--orange) !important;
}

.open-dict-btn:hover{
  background-color: var(--orange-hover) !important;
}

.audio-params-row{
  min-height: 70px;
}

.download-row{
  padding-left: 5%;
  padding-right: 5%;
}

.audio-params-block{
  text-align: right;
}

.audio-params-block-position{
  float: left;
  padding: 15px 15px;
}

/* Audio Params Block */

.params-label{
  display: inline-block;
  font-size: 14px !important;
  margin-right: 20px;
}

.audio-params-btn{
  display: inline-block;
  width: 42px;
  height: 42px;
  cursor: pointer;
  position: absolute;
  margin-left: 12%;
}

.open-params{
  fill: var(--grey);
  margin-top: 6px;
}

.open-params:hover{
  fill: var(--orange);
}

.close-params{
  fill: var(--orange);
  margin-top: 6px;
}

.close-params:hover{
  fill: var(--orange-hover);
}

.slider{
  display: inline-block;
  cursor: pointer;
}

.value{
  max-width: 15px;
}

input[type=range]::-ms-track {
  width: 190px;
  height: 5px;
  
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;

  /*remove default tick marks*/
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}
input[type=range]::-ms-fill-upper {
  background: #d3d3d3;
  border-radius: 10px;
}
input[type=range]::-ms-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--orange);
}
input[type=range]:focus::-ms-fill-lower {
  background: #888;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ccc;
}

input[type=range]{
  /* fix for FF unable to apply focus style bug  */

  /*required for proper track sizing in FF*/
  width: 100px;
}

input[type=range]::-moz-range-track {
  width: 190px;
  height: 5px;
  background: #d3d3d3;
  border: none;
  border-radius: 3px;
}

input[type=range]::-moz-range-thumb {
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--orange);
}

/*hide the outline behind the border*/
input[type=range]:-moz-focusring{
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type=range]:focus::-moz-range-track {
  background: #ccc;
}

input[type=range]{
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 190px;
  height: 5px;
  background: #d3d3d3;
  border: none;
  border-radius: 3px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: var(--orange);
  margin-top: -4px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

/* Audio Player */
.rhap_time {
  color: var(--text-color) !important;
}
.rhap_container {
  background-color: var(--background-primary) !important;
  width: 100% !important;
  box-shadow: none !important;
  display: inline-flex !important;
}
.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: var(--blue) !important;
}

.rhap_main-controls-button,
.rhap_volume-button {
  color: var(--blue) !important;
}

.rhap_volume-controls {
  align-items: center !important;
}

.rhap_main-controls {
  padding: 0px 30px 0px 0px;
}

.rhap_rewind-button,
.rhap_forward-button {
  display: none;
}

/* Download */

.audioFormat {
  padding: 8px 15px;
}

.audioFormatChoice{
  border-bottom: 1px solid var(--blue);
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  color: var(--blue);
  background-color: var(--background-primary);
  text-transform: uppercase;
  padding-bottom: 5px;
}

.audioFormatChoice:focus, .audioFormatChoice:active{
  outline: transparent;
}

.selectFileName {
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px 5px;
  width: 110px;
  display:table-cell;
  font-size: 14px;
  color: var(--text-color);
}

.file-name{
  margin-top: 20px;
  padding-bottom: 20px;
  display: table;
  width: 100%;
}

.containerFileName {
  display:table-cell;
}

.fileNameInput {
  width:100%;
  border-bottom: 1px solid var(--blue);
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  color: var(--blue);
  background-color: var(--background-primary);
}

/*.file-name input{
  width: 100%;
  border-bottom: 1px solid var(--blue);
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
  padding-bottom: 5px;
  color: var(--blue);
  background-color: var(--background-primary);
}*/

.file-name input:focus, .file-name input:active{
  outline: transparent;
}

.selectAudioFormat {
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px 5px;
  width: 140px;
  font-size: 14px;
  color: var(--text-color);
}

.modal-content {
  background-color: var(--background-primary) !important;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.invert-svg {
  filter: invert(100%);
}

.content-login {
  width: 250px;
  height: 350px;
  border: 7px solid;
  border-color: var(--blue);
  border-radius: 5px;
  /*background-color:#ecebeb;*/
}

.content-changePwd {
  margin: auto;
  width: 250px;
  height: 265px;
  border: 7px solid;
  border-color: var(--blue);
  border-radius: 5px;
  /*background-color:#ecebeb;*/
}

.connexion {
  display: flex;
  justify-content: center;
  color: var(--blue);
}

.lock-img {
  fill: #f66e4a;
  margin-top: 10px;
  width: 30px;
  height: 30px;
}

.MuiFormControl-root {
  margin: 10px 10px 10px !important;
}

.visibility-btn {
  width: 30px;
  height: 30px;
}

.loginDiv {
  margin: 70px auto;
  width: fit-content;
  margin-bottom: 10px;
}

.login-btn {
  margin: 17px 28px;
  font-size: 12px !important;
}

.MuiFormLabel-root {
  font-size: 12px !important;
}

.MuiInputBase-input {
  font-size: 12px !important;
}

.input-email {
  width: 200px;
}

.input-captcha {
  width: 200px;
}

.alert-auth {
  display: flex;
  justify-content: center;
}

.content-admin {
  margin: 70px auto;
  justify-content: center;
}

.captcha {
  display: flex;
  justify-content: center;
}